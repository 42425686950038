import type { Article } from '~/types/demegro'
import { defineStore } from 'pinia'

export const useSearchStore = defineStore('searchStore', () => {
    const searchResult = ref<Article[] | null>(null)
    const currentSearchQuery = ref<string>('')
    const searchType = ref<'query' | 'producttype'>('query')

    function clear() {
        searchResult.value = null
        currentSearchQuery.value = ''
    }

    function setSearch(type: 'query' | 'producttype', query: string, result: Article[] | null) {
        searchType.value = type
        currentSearchQuery.value = query
        searchResult.value = result
    }

    return {
        searchResult,
        currentSearchQuery,
        searchType,
        clear,
        setSearch,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}
